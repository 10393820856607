<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <!-- <v-card elevation-0 flat>
      <v-layout wrap justify-start>
        <v-flex text-start xs12 sm6>
          <v-layout wrap justify-space-between py-2>
            <v-flex xs10 offset-2 text-left text-uppercase pl-12>
              <span style="color: #000000; font-family: poppinsbold">Shopping</span>
            </v-flex>
            <v-flex text-left xs10 offset-2 pl-12>
              <span style="color: #9e9e9e; font-family: poppinsregular">{{
                product.name
              }}</span>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <v-divider></v-divider>
        </v-flex>
      </v-layout>
    </v-card> -->
    <v-layout wrap justify-center v-if="product.images">
      <v-flex xs12 lg9 xl11>
        <!-- <v-layout wrp justify-center>
          <v-flex xs12 text-left py-4>
            <ImageZoomer />
          </v-flex>
        </v-layout> -->
        <v-layout wrap justify-center fill-height v-if="product.images">
          <v-flex xs12 sm12 md5 lg5 xl4>
            <v-layout wrap>
              <v-flex xs12 class="hidden-sm-and-up">
                <viewer :images="product.images" :key="product.images.length">
                  <v-carousel hide-delimiter-background delimiter-icon="mdi-minus" :show-arrows="false" light>
                    <v-carousel-item v-for="item in product.images" :key="item">
                      <v-sheet height="100%" tile>
                        <v-row class="fill-height" align="center" justify="center">
                          <img :src="mediaURL + item" height="350px" style="object-fit: contain" />
                        </v-row>
                      </v-sheet>
                    </v-carousel-item>
                  </v-carousel>
                </viewer>
              </v-flex>
              <v-flex xs12 sm2 class="hidden-xs-only">
                <v-card tile flat>
                  <v-layout wrap justify-center>
                    <v-flex xs3 sm12 v-for="(item, i) in product.images" :key="i">
                      <v-card :class="j == i ? 'bgremm' : 'bgrem'" @mouseover="showImage(item), (j = i)" flat tile>
                        <v-layout wrap justify-center>
                          <v-flex pa-2>
                            <v-img :src="mediaURL + item" height="100px" contain>
                              <template v-slot:placeholder>
                                <ImageLoader />
                              </template>
                            </v-img>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 sm10 class="hidden-xs-only">
                <v-card flat tile height="100%">
                  <v-layout wrap justify-center px-2>
                    <!-- <v-flex lg2>
                      <v-spacer></v-spacer>
                    </v-flex> -->
                    <v-flex xs12 lg12 align-self-center>
                      <span class="hidden-md-and-up">
                        <v-img v-if="!curImage" :src="mediaURL + product.images[0]" max-height="60vh" contain>
                          <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular indeterminate color="#FF2323"></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <v-img v-if="curImage" :src="mediaURL + curImage" max-height="60vh" contain>
                          <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular indeterminate color="#FF2323"></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </span>
                      <span class="hidden-sm-and-down">
                        <image-magnifier v-if="!curImage" :src="mediaURL + product.images[0]"
                          :zoom-src="mediaURL + product.images[0]" width="100%" height="100%" zoom-width="600"
                          zoom-height="450"></image-magnifier>
                        <image-magnifier v-if="curImage" :src="mediaURL + curImage" :zoom-src="mediaURL + curImage"
                          width="100%" height="100%" zoom-width="600" zoom-height="450"></image-magnifier>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <!-- <v-flex xs12>
                <v-card outlined tile height="100%">
                  <v-layout wrap justify-center fill-height>
                    <v-flex xs11 sm2 md4 pt-12>
                      <v-card tile flat>
                        <v-layout wrap justify-center>
                          <v-flex
                            xs4
                            sm12
                            py-2
                            v-for="(item, i) in product.images"
                            :key="i"
                          >
                            <v-card
                              :ripple="false"
                              @click="showImage(item)"
                              flat
                              tile
                            >
                              <v-layout wrap justify-center>
                                <v-flex pa-1>
                                  <v-img
                                    :src="mediaURL + item"
                                    height="80px"
                                    contain
                                  >
                                    <template v-slot:placeholder>
                                      <ImageLoader />
                                    </template>
                                  </v-img>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex xs12 sm10 md8 align-self-center pt-6>
                      <div v-if="!curImage" class="container">
                        <inner-image-zoom
                          zoomType="hover"
                          :hideHint="true"
                          :zoomPreload="true"
                          :src="mediaURL + product.images[0]"
                          :zoomSrc="mediaURL + product.images[0]"
                          :zoomScale="1.8"
                        >
                        </inner-image-zoom>
                      </div>
                      <div v-if="curImage" class="container">
                        <inner-image-zoom
                          :zoomScale="1.8"
                          zoomType="hover"
                          :hideHint="true"
                          :zoomPreload="true"
                          :src="mediaURL + curImage"
                          :zoomSrc="mediaURL + curImage"
                        >
                        </inner-image-zoom>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex> -->
              <v-flex xs12>
                <!-- justify-md-end justify-sm-center -->
                <v-layout wrap justify-center px-2>
                  <!-- <v-flex
                    xs12
                    sm3
                    md5
                    lg5
                    pr-2
                    pl-2
                    pl-sm-0
                    py-1
                    py-sm-3
                    v-if="this.$store.state.isLoggedIn"
                  >
                    <v-btn
                      tile
                      x-large
                      dark
                      v-if="product.units.length > 0"
                      block
                      @click="verifyToCartSize()"
                      style="
                        background-color: #e27826;
                        text-transform: none;
                        font-family: poppinssemibold;
                      "
                      text
                      :disabled="sizeType.stock <= 0"
                      >Add to Cart</v-btn
                    >
                    <v-btn
                      tile
                      x-large
                      dark
                      block
                      v-if="product.units.length <= 0"
                      @click="verifyToCart()"
                      style="
                        background-color: #e27826;
                        text-transform: none;
                        font-family: poppinssemibold;
                      "
                      text
                      :disabled="product.totalQuantity <= 0"
                      >Add to Cart</v-btn
                    >
                  </v-flex> -->
                  <!-- <v-flex
                    xs12
                    sm3
                    md5
                    lg5
                    pr-2
                    pl-2
                    pl-sm-0
                    py-1
                    py-sm-3
                    v-if="!this.$store.state.isLoggedIn"
                  > -->
                  <!-- <v-btn
                      tile
                      x-large
                      @click="$router.push('/login')"
                      dark
                      block
                      style="
                        background-color: #E27826;
                        text-transform: none;
                        font-family: poppinssemibold;
                      "
                      text
                      >Add to Cart</v-btn
                    > -->

                  <!-- <v-btn
                      tile
                      x-large
                      dark
                      v-if="product.units.length > 0"
                      block
                      @click="verifyToCartSizeGuest()"
                      style="
                        background-color: #e27826;
                        text-transform: none;
                        font-family: poppinssemibold;
                      "
                      text
                      :disabled="sizeType.stock <= 0"
                      >Add to Cart</v-btn
                    >
                    <v-btn
                      tile
                      x-large
                      dark
                      block
                      v-if="product.units.length <= 0"
                      @click="verifyToCartGuest()"
                      style="
                        background-color: #e27826;
                        text-transform: none;
                        font-family: poppinssemibold;
                      "
                      text
                      :disabled="product.totalQuantity <= 0"
                      >Add to Cart</v-btn
                    > -->
                  <!-- </v-flex> -->
                  <!-- BUYNOW -->
                  <v-flex xs12 sm12 md12 lg12 pl-2 pr-2 pr-sm-0 py-1 py-sm-3 v-if="this.$store.state.isLoggedIn">
                    <v-btn tile x-large dark v-if="product.units.length > 0" block @click="
                      $router.push({
                        path: '/productDetails/',

                        query: {
                          id: id,
                          unitid: productType1._id,
                          sizeid: sizeType._id,
                          quantity: qty,
                          sizename: productType1.type,
                          subsize: sizeType.sizename,
                        },
                      })
                      " style="
                        background-color: #e27a26;
                        text-transform: none;
                        font-family: poppinssemibold;
                      " text :disabled="sizeType.stock <= 0">Buy Now</v-btn>
                    <v-btn x-large tile dark block v-if="product.units.length <= 0" @click="
                      $router.push({
                        path: '/productDetails/',

                        query: {
                          id: id,
                          unitid: productType1._id,
                          quantity: mainqty,
                          sizename: productType1.type,
                          subsize: sizeType.sizename,
                        },
                      })
                      " style="
                        background-color: #e27a26;
                        text-transform: none;
                        font-family: poppinssemibold;
                      " text :disabled="product.totalQuantity <= 0">Buy Now</v-btn>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12 pl-2 pr-2 pr-sm-0 py-1 py-sm-3 v-if="!this.$store.state.isLoggedIn">
                    <v-btn tile x-large @click="$router.push('/login')" dark block style="
                        background-color: #e27a26;
                        text-transform: none;
                        font-family: poppinssemibold;
                      " text>Buy Now</v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md7 lg7 xl8>
            <v-layout wrap justify-center>
              <!-- <v-flex xs11>
            <v-divider></v-divider>
          </v-flex> -->

              <v-flex xs11>
                <v-layout wrap justify-start>
                  <!-- <v-flex xs12 text-left>
                    <span
                      style="
                        font-family: poppinsbold;
                        font-size: 22px;
                        color: #000000;
                      "
                    >
                      {{ product.name }}
                    </span>
                  </v-flex> -->
                  <v-flex xs12 px-0 px-sm-2 pt-0>
                    <v-card tile flat>
                      <v-layout wrap justify-center pa-4 px-6 pt-0>
                        <v-flex xs12 text-left>
                          <span style="
                              font-family: poppinsbold;
                              font-size: 23px;
                              color: #1a1919;
                            ">{{ product.name }} </span><br />
                          <!-- <span
                    style="
                      font-family: ibmlight;
                      color: #3c3c3c;
                      font-size: 14px;
                    "
                    >WTI</span
                  > -->
                        </v-flex>

                        <v-flex text-left xs12 v-if="product.units.length > 0">
                          <v-flex text-left xs12 v-if="newprice <= 0">
                            <span style="
                                font-family: poppinssemibold;
                                font-size: 20px;
                              ">Rs. {{ sizeType.price }}</span>
                          </v-flex>
                          <v-flex text-left xs12 v-else>
                            <span style="
                                font-family: poppinssemibold;
                                font-size: 20px;
                              ">Rs. {{ newprice }}</span>
                          </v-flex>
                        </v-flex>
                        <v-flex text-left xs12 v-else>
                          <v-flex text-left xs12 v-if="newmainprice <= 0">
                            <span style="
                                font-family: poppinssemibold;
                                font-size: 20px;
                              ">Rs. {{ product.price }}</span>
                          </v-flex>
                          <v-flex text-left xs12 v-else>
                            <span style="
                                font-family: poppinssemibold;
                                font-size: 20px;
                              ">Rs. {{ newmainprice }}</span>
                          </v-flex>
                        </v-flex>
                        <v-flex text-left xs12>
                          <v-layout wrap justify-start>
                            <v-flex xs5 sm2 lg2 xl1 align-self-center>
                              <span class="poppinssemibold">Ratings</span>
                            </v-flex>
                            <v-flex xs7 sm10 align-self-center text-left>
                              <v-rating v-model="product.averagerating" color="yellow darken-3"
                                background-color="grey darken-1" empty-icon="$ratingFull" half-increments hover small
                                readonly></v-rating>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex text-left xs12 pt-2>
                          <span style="
                                      font-family: poppinssemibold;
                                      font-size: 16px;
                                      color: #53a874;
                                    ">For sale only in India</span>
                        </v-flex>
                        <v-flex xs12 py-6>
                          <v-layout justify-start wrap v-if="product.units.length > 0">
                            <v-flex xs12 text-left py-2>
                              <span class="poppinssemibold">Size</span> &nbsp;
                              <span v-for="(item, i) in product.units" :key="i" class="px-1">
                                <v-btn small tile width="70px" height="32px" depressed outlined
                                  :disabled="item.stock <= 0 ? true : false" :style="item.stock <= 0
                                      ? 'background: linear-gradient(to top right, #fff calc(50% - 1px), black , #fff calc(50% + 1px) )'
                                      : ''
                                    " @click="changeSize(item)" :ripple="false" :color="productType1.type == item.type
      ? '#FF0000'
      : '#919191'
    " class="sizebutton px-2">
                                  {{ item.type }}
                                </v-btn>
                              </span>
                            </v-flex></v-layout>

                          <v-layout justify-start wrap v-if="product.units.length > 0">
                            <v-flex xs12 text-left py-2>
                              <span v-for="(item, i) in productType1.size" :key="i" class="px-1">
                                <v-btn small tile width="55px" depressed outlined
                                  :disabled="item.stock <= 0 ? true : false" :style="item.stock <= 0
                                      ? 'background: linear-gradient(to top right, #fff calc(50% - 1px), black , #fff calc(50% + 1px) )'
                                      : ''
                                    " @click="changePrice(item)" :ripple="false" :color="sizeType.sizename == item.sizename
      ? '#FF0000'
      : '#919191'
    " class="sizebutton px-2">
                                  {{ item.sizename }}
                                </v-btn>
                              </span>
                            </v-flex>
                            <v-flex xs12 text-left py-2 v-if="sizeType.stock > 0">
                              <v-layout wrap>
                                <v-flex xs5 sm3 md4 lg4 xl2 text-left align-self-center>
                                  <span style="
                                      font-family: poppinssemibold;
                                      font-size: 16px;
                                      color: #000000;
                                    ">Choose quantity</span>
                                </v-flex>
                                <v-flex xs7 sm4 md4 lg4 xl3 pa-2 px-xl-12 align-self-center text-center>
                                  <v-card tile outlined flat>
                                    <v-layout wrap justify-center>
                                      <v-flex align-self-center pa-1>
                                        <v-icon @click="qty--" :disabled="qty <= 1">mdi-minus</v-icon>
                                      </v-flex>
                                      <v-flex align-self-center pa-1>
                                        <span class="poppinssemibold">
                                          {{ qty }}
                                        </span>
                                      </v-flex>
                                      <v-flex align-self-center pa-1>
                                        <v-icon @click="qty++" :disabled="qty == sizeType.stock">mdi-plus</v-icon>
                                      </v-flex>
                                    </v-layout>
                                  </v-card>
                                  <!-- <v-text-field
                                    :style="
                                      $vuetify.breakpoint.name == 'lg'
                                        ? 'width:110px;'
                                        : $vuetify.breakpoint.name == 'md'
                                        ? 'width:110px;'
                                        : $vuetify.breakpoint.name == 'xl'
                                        ? 'width:110px;'
                                        : $vuetify.breakpoint.name == 'sm'
                                        ? 'width:115px;'
                                        : 'width:110px;'
                                    "
                                    class="quantBox"
                                    placeholder="1"
                                    outlined
                                    hide-details
                                    dense
                                    color="#CCCCCC"
                                    background-color="#FFFFFF"
                                    append-outer-icon="mdi-plus"
                                    readonly
                                    v-model="qty"
                                  >
                              
                                    <template v-slot:prepend>
                                      <v-icon
                                        @click="qty--"
                                        :disabled="qty <= 1"
                                      >
                                        mdi-minus
                                      </v-icon>
                                    </template>
                                    <template v-slot:append-outer>
                                      <v-icon
                                        @click="qty++"
                                        :disabled="qty == sizeType.stock"
                                      >
                                        mdi-plus
                                      </v-icon>
                                    </template>
                                  </v-text-field> -->
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                          <!-- NO CATEGORY  -->
                          <v-layout justify-start wrap v-else>
                            <v-flex xs12 text-left py-2 v-if="product.totalQuantity > 0">
                              <v-layout wrap>
                                <v-flex xs5 sm3 md4 lg4 xl2 text-left align-self-center>
                                  <span style="
                                      font-family: poppinssemibold;
                                      font-size: 16px;
                                      color: #000000;
                                    ">Choose quantity</span>
                                </v-flex>
                                <v-flex xs7 sm4 md4 lg4 xl3 pa-2 px-xl-12 align-self-center text-center>
                                  <v-card tile outlined flat>
                                    <v-layout wrap justify-center>
                                      <v-flex align-self-center pa-1>
                                        <v-icon @click="mainqty--" :disabled="mainqty <= 1">mdi-minus</v-icon>
                                      </v-flex>
                                      <v-flex align-self-center pa-1>
                                        <span class="poppinssemibold">
                                          {{ mainqty }}
                                        </span>
                                      </v-flex>
                                      <v-flex align-self-center pa-1>
                                        <v-icon @click="mainqty++" :disabled="mainqty == product.totalQuantity
                                          ">mdi-plus</v-icon>
                                      </v-flex>
                                    </v-layout>
                                  </v-card>
                                  <!-- <v-text-field
                                    :style="
                                      $vuetify.breakpoint.name == 'lg'
                                        ? 'width:110px;'
                                        : $vuetify.breakpoint.name == 'md'
                                        ? 'width:110px;'
                                        : $vuetify.breakpoint.name == 'xl'
                                        ? ''
                                        : $vuetify.breakpoint.name == 'sm'
                                        ? 'width:115px;'
                                        : 'width:110px;'
                                    "
                                    placeholder="1"
                                    outlined
                                    class="quantBox"
                                    hide-details
                                    dense
                                    color="#CCCCCC"
                                    background-color="#FFFFFF"
                                    append-outer-icon="mdi-plus"
                                    readonly
                                    v-model="mainqty"
                                  >
                                  
                                    <template v-slot:prepend>
                                      <v-icon
                                        @click="mainqty--"
                                        :disabled="mainqty <= 1"
                                      >
                                        mdi-minus
                                      </v-icon>
                                    </template>
                                    <template v-slot:append-outer>
                                      <v-icon
                                        @click="mainqty++"
                                        :disabled="
                                          mainqty == product.totalQuantity
                                        "
                                      >
                                        mdi-plus
                                      </v-icon>
                                    </template>
                                  </v-text-field> -->
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>

                        <v-flex py-2 pb-6 xs12 text-left v-if="product.units.length > 0">
                          <span class="poppinssemibold">Availability :</span>
                          <span v-if="sizeType.stock > 0" class="" style="
                              font-family: poppinsregular;
                              font-size: 14px;
                              color: #53a874;
                            ">
                            In Stock
                          </span>
                          <span v-else class="" style="
                              font-family: poppinsregular;
                              font-size: 14px;
                              color: red;
                            ">
                            Out of Stock
                          </span>
                        </v-flex>
                        <v-flex py-2 pb-6 xs12 text-left v-else>
                          <span class="poppinssemibold">Availability :</span>
                          <span v-if="product.totalQuantity > 0" class="" style="
                              font-family: poppinsregular;
                              font-size: 14px;
                              color: #53a874;
                            ">
                            In Stock
                          </span>
                          <span v-else class="" style="
                              font-family: poppinsregular;
                              font-size: 14px;
                              color: red;
                            ">
                            Out of Stock
                          </span>
                        </v-flex>

                        <v-layout justify-start wrap>
                          <v-flex text-left xs12 sm12 md12>
                            <span style="font-family: poppinsbold; font-size: 18px">Product Details </span>
                            <span style="font-family: poppinsregular" v-html="product.description"></span>
                          </v-flex>
                        </v-layout>
                        <v-flex text-left xs12 py-5> </v-flex>
                        <v-layout justify-start wrap>
                          <v-flex text-left xs12 sm8 md10 v-for="(item, i) in product.speciality" :key="i">
                            <span style="font-family: poppinsregular">{{
                              item
                            }}</span>
                          </v-flex>
                        </v-layout>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 py-6 px-2>
            <!-- <v-tabs
              v-model="tab"
              background-color="transparent"
              color="basil"
              grow
            >
              <v-tab v-for="item in items" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs> -->
            <!-- <v-tabs
              background-color="#ffffff"
              center-active
              light
              color="orange"
            >
              <v-tab>
                <span class="popsemibold">Reviews ({{ revcount }})</span>
              </v-tab>
              <v-tab>
                <span class="popsemibold">Description</span>
              </v-tab>

              <v-tab-item>
                <v-card flat v-if="reviews.length > 0">
                  <v-layout wrap>
                    <v-flex xs12 pa-2 v-for="(sub, s) in reviews" :key="s">
                      <v-card flat>
                        <v-layout wrap>
                          <v-flex xs12 pa-1>
                            <v-rating
                              v-model="sub.rating"
                              color="orange"
                              background-color="grey darken-1"
                              empty-icon="$ratingFull"
                              half-increments
                              hover
                              small
                              readonly
                            ></v-rating>
                          </v-flex>
                          <v-flex xs12 pa-1>
                            <span class="popregular">
                              {{ sub.comment }}
                            </span>
                          </v-flex>
                          <v-flex
                            xs12
                            pa-2
                            align-self-center
                            text-right
                            v-if="sub.user"
                          >
                            <span
                              class="popregular"
                              style="color: grey; font-size: 12px"
                            >
                              <i
                                >{{ sub.user.firstname }}&nbsp;{{
                                  sub.user.lastname
                                }}</i
                              >
                            </span>
                          </v-flex>
                          <v-flex xs12 py-2>
                            <v-divider></v-divider>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex
                      xs12
                      v-if="reviews.length > 8"
                      px-sm-12
                      align-self-end
                      text-center
                    >
                      <v-btn
                        text
                        small
                        :to="'/AllReviews/' + product._id"
                        class="text-none"
                      >
                        <span>View more</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card>
                <v-card flat v-else>
                  <v-card-text>
                    <span style="color: #000" class="popregular"
                      >No reviews for this product!</span
                    >
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item class="py-2">
                <v-card flat tile outlined class="pa-4">
                  <span
                    style="color: #000"
                    class="popregular text-left"
                    v-html="product.DetailedDescription"
                  ></span>
                </v-card>
              </v-tab-item>
            </v-tabs> -->
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- <v-flex xs12 v-if="id" pt-12 v-scroll="reveal" class="reveal">
        <v-img
          :src="require('./../../assets/Images/cbg.jpeg')"
          class="align-center"
        >
          <v-layout wrap justify-center>
            <v-flex xs12 lg11 xl11>
              <SimilarProducts :id="id" :similarProducts="similarProducts" />
            </v-flex>
          </v-layout>
        </v-img>
      </v-flex> -->
    </v-layout>
    <v-dialog persistent v-model="popUp" max-width="600px">
      <v-card>
        <v-card-text style="font-family: poppinslight">
          <v-layout wrap justify-center>
            <v-flex xs12 align-self-center text-center py-4>
              <span class="tableValue">Reminder</span>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs12 lg12 align-self-center text-justify py-0 pr-md-0>
              <span style="font-size: 18px" class="tableValue">
                Do you mind removing existing Product from Cart?
              </span>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="#FF4444" small depressed :ripple="false" @click="popUp = false">Cancel</v-btn>
          <v-btn dark color="#53A874" small depressed :ripple="false"
            @click="flg ? addProdCart() : addProdCartSize()">OK</v-btn>
          <!-- <v-spacer></v-spacer> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
// import InnerImageZoom from "vue-inner-image-zoom";
// import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
// import ImageZoomer from "./imageZoomer";
// import SimilarProducts from "./similarProducts";
export default {
  // props: ["id"],
  components: {
    // ImageZoomer,
    // "inner-image-zoom": InnerImageZoom,
    // SimilarProducts,
  },
  data() {
    return {
      j: 0,
      appLoading: false,
      popUp: false,
      flg: false,
      ServerError: false,
      showSnackBar: false,
      value: null,
      timeout: 5000,
      rating: 4.5,
      product: [],
      // items: ["1KG", "2KG", "3KG", "4KG", "5KG"],
      msg: null,
      productType: {},
      productType1: {},
      sizeType: {},
      selectedSlot: {},
      totalGuest: 0,
      qty: 1,
      mainqty: 1,
      newprice: 0,
      newmainprice: 0,
      curImage: null,
      similarProducts: null,
      tab: null,
      reviews: null,
      revcount: 0,
      id: '647421b13256d471b522e93b',
      items: ["Description", "Reviews"],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
  watch: {
    qty() {
      this.newprice = this.qty * this.sizeType.price;
    },
    mainqty() {
      this.newmainprice = this.mainqty * this.product.price;
    },
  },
  computed: {
    appCartCount() {
      return this.$store.state.cartItems;
    },
    // appCartType() {
    //   return this.$store.state.cartType;
    // },
  },
  beforeMount() {
    this.getData();
    this.value = this.$route.query.value;
  },
  methods: {
    showImage(item) {
      this.curImage = item;
    },
    changeSize(item) {
      this.productType1 = item;
      if (item.size.length > 0) this.changePrice(item.size[0]);
      else {
        // console.log("mine",item)
        this.changePrice(item);
      }
    },
    changePrice(item) {
      this.sizeType = JSON.parse(JSON.stringify(item));

      if (item.size) this.sizeType._id = null;
      this.qty = 1;
      this.newprice = item.price;
      // alert( this.newprice)
    },
    testrating() {
      axios({
        method: "POST",
        url: "/rating/add/update/product",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          productid: this.id,
          rating: "3",
          comment: "Very good! Product quality is quite good.",
        },
      })
        .then((response) => {
          if (response.data.status) {
            console.log("test reting");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/similar/product/get/v1",
        data: {
          id: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            // this.testrating()
            this.product = response.data.data;
            this.reviews = response.data.reviews;
            this.revcount = response.data.reviewcount;
            this.similarProducts = response.data.similarproduct;
            // this.productType = this.product.units[0];
            // this.product.units[0].stock=0;
            for (let i = 0; i < this.product.units.length; i++) {
              this.productType1 = this.product.units[0];
              for (let j = 0; j < this.productType1.size.length; j++) {
                this.sizeType = this.productType1.size[0];
              }
            }
            for (let i = 0; i < this.product.units.length; i++) {
              if (this.product.units[i].size.length > 0) {
                for (var j = 0; j < this.product.units[i].size.length; j++) {
                  if (this.product.units[i].size[j].stock > 0) {
                    this.productType = this.product.units[i].size[j];
                    break;
                  }
                }
                break;
              } else {
                this.changePrice(this.product.units[i]);
                break;
              }
            }
            // this.newprice = this.productType.price;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    verifyToCartSize() {
      if (this.appCartCount > 0) {
        // if (this.appCartType == "Programme") {
        //   this.popUp = true;
        // } else {
        this.addProdCartSize();
        // }
      } else {
        this.addProdCartSize();
      }
    },
    verifyToCart() {
      this.flg = true;
      if (this.appCartCount > 0) {
        // if (this.appCartType == "Programme") {
        //   this.popUp = true;
        // } else {
        this.addProdCartGuest();
        // }
      } else {
        this.addProdCartGuest();
      }
    },
    // Guest mode
    verifyToCartSizeGuest() {
      if (this.appCartCount > 0) {
        // if (this.appCartType == "Programme") {
        //   this.popUp = true;
        // } else {
        this.addProdCartSizeGuest();
        // }
      } else {
        this.addProdCartSizeGuest();
      }
    },
    verifyToCartGuest() {
      this.flg = true;
      if (this.appCartCount > 0) {
        // if (this.appCartType == "Programme") {
        //   this.popUp = true;
        // } else {
        this.addProdCart();
        // }
      } else {
        this.addProdCart();
      }
    },

    addProdCartSizeGuest() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/productcart/add",
        data: {
          product: this.id,
          quantity: this.qty,
          sizeid: this.sizeType._id,
          unitid: this.productType1._id,
          // removeProgrammeCart: true,
          guestId: localStorage.getItem("GuestID"),
        },
        // headers: {
        //   token: localStorage.getItem("token"),
        // },
      })
        .then((response) => {
          this.getUser();
          this.appLoading = false;
          if (response.data.status) {
            this.popUp = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            setTimeout(() => {
              this.$router.push(
                "/productCart/" +
                "?id=" +
                this.id +
                "&unitid=" +
                this.productType1._id +
                "&sizeid=" +
                this.sizeType._id +
                "&quantity=" +
                this.qty +
                "&sizename=" +
                this.productType1.type +
                "&subsize=" +
                this.sizeType.sizename
              );
            }, 1000);
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            // this.$router.push('/login')
            setTimeout(() => {
              this.$router.push(
                "/productCart/" +
                "?id=" +
                this.id +
                "&unitid=" +
                this.productType1._id +
                "&sizeid=" +
                this.sizeType._id +
                "&quantity=" +
                this.qty +
                "&sizename=" +
                this.productType1.type +
                "&subsize=" +
                this.sizeType.sizename
              );
            }, 1000);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addProdCartGuest() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/productcart/add",
        data: {
          product: this.id,
          quantity: this.mainqty,
          sizeid: this.sizeType._id,
          unitid: this.productType1._id,
          // removeProgrammeCart: true,
          guestId: localStorage.getItem("GuestID"),
        },
        // headers: {
        //   token: localStorage.getItem("token"),
        // },
      })
        .then((response) => {
          this.appLoading = false;
          this.getUser();
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            setTimeout(() => {
              this.$router.push(
                "/productCart/" +
                "?id" +
                this.id +
                "&unitid" +
                this.productType1._id +
                "&sizeid" +
                this.sizeType._id +
                "&quantity" +
                this.qty +
                "&sizename" +
                this.productType1.type +
                "&subsize" +
                this.sizeType.sizename
              );
            }, 1000);
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            // this.$router.push('/login')
            setTimeout(() => {
              this.$router.push(
                "/productCart/" +
                "?id" +
                this.id +
                "&unitid" +
                this.productType1._id +
                "&sizeid" +
                this.sizeType._id +
                "&quantity" +
                this.qty +
                "&sizename" +
                this.productType1.type +
                "&subsize" +
                this.sizeType.sizename
              );
            }, 1000);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    // Guest mode end
    addProdCartSize() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/productcart/add",
        data: {
          product: this.id,
          quantity: this.qty,
          sizeid: this.sizeType._id,
          unitid: this.productType1._id,
          // removeProgrammeCart: true,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.getUser();
          this.appLoading = false;
          if (response.data.status) {
            this.popUp = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            setTimeout(() => {
              this.$router.push(
                "/productCart/" +
                "?id=" +
                this.id +
                "&unitid=" +
                this.productType1._id +
                "&sizeid=" +
                this.sizeType._id +
                "&quantity=" +
                this.qty +
                "&sizename=" +
                this.productType1.type +
                "&subsize=" +
                this.sizeType.sizename
              );
            }, 1000);
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            // this.$router.push('/login')
            setTimeout(() => {
              this.$router.push(
                "/productCart/" +
                "?id=" +
                this.id +
                "&unitid=" +
                this.productType1._id +
                "&sizeid=" +
                this.sizeType._id +
                "&quantity=" +
                this.qty +
                "&sizename=" +
                this.productType1.type +
                "&subsize=" +
                this.sizeType.sizename
              );
            }, 1000);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addProdCart() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/productcart/add",
        data: {
          product: this.id,
          quantity: this.mainqty,
          sizeid: this.sizeType._id,
          unitid: this.productType1._id,
          // removeProgrammeCart: true,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.getUser();
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            setTimeout(() => {
              this.$router.push(
                "/productCart/" +
                "?id" +
                this.id +
                "&unitid" +
                this.productType1._id +
                "&sizeid" +
                this.sizeType._id +
                "&quantity" +
                this.qty +
                "&sizename" +
                this.productType1.type +
                "&subsize" +
                this.sizeType.sizename
              );
            }, 1000);
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            // this.$router.push('/login')
            setTimeout(() => {
              this.$router.push(
                "/productCart/" +
                "?id" +
                this.id +
                "&unitid" +
                this.productType1._id +
                "&sizeid" +
                this.sizeType._id +
                "&quantity" +
                this.qty +
                "&sizename" +
                this.productType1.type +
                "&subsize" +
                this.sizeType.sizename
              );
            }, 1000);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getUser() {
      if (!localStorage.getItem("GuestID")) {
        axios({
          method: "POST",
          url: "/user/me/product",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status) {
              this.$store.commit("userData", response.data.userdata);
              this.$store.commit("userType", response.data.userdata.user.role);
              this.$store.commit("changeCart", response.data.cartcount);
              this.$store.commit(
                "CartType",
                response.data.userdata.user.cartType
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    addToCart(id) {
      if (!this.$store.state.isLoggedIn) {
        this.$router.push("/login");
        return;
      } else if (!this.$store.state.isLoggedI) {
        this.$router.push("/productDetails/" + id);
      }
    },

    reveal() {
      var reveals = document.querySelectorAll(".reveal");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    },

    // window.addEventListener("scroll", reveal);
  },
};
</script>
<style scoped>
.custom.v-text-field>.v-input__control>.v-input__slot:before {
  border-style: none;
  /* text-align: center; */
}

#slotInput {
  text-align: center;
}

.boxed {
  text-align: center !important;
  padding-inline-start: 20px;
  border-radius: 0px;
}

.quantBox {
  align-content: center !important;
}

.quantBox>>>input {
  align-content: center !important;
  text-align: center !important;
}

/* .quantBox {
   width: 150px !important;
} */
.container {
  position: relative;
  text-align: center;
  color: white;
}

/* Bottom left text */
.top-left {
  position: absolute;
  top: 12px;
  left: 11px;
}

.top-left-xs {
  position: absolute;
  top: 12px;
  left: 11px;
}

.bottom-right-xs {
  position: absolute;
  bottom: -8vh;
  right: 20px;
}

.bottom-right-sm {
  position: absolute;
  bottom: -7vh;
  right: 12px;
}

.bottom-right-md {
  position: absolute;
  bottom: -7vh;
  right: 10px;
}

.bottom-right {
  position: absolute;
  bottom: -8vh;
  right: 10px;
}

.colorselector.v-sheet.v-card {
  border-radius: 35px;
}

.sizebutton.v-btn:not(.v-btn--round).v-size--small {
  /* min-width: 50px; */
  padding: 0 12px !important;
  min-width: 30px !important;
}

.bgrem:hover {
  border: 1px solid #d3d3d3 !important;
}

.bgremm {
  border: 1px solid #d3d3d3 !important;
}

.reveal {
  position: relative;
  transform: translateY(50px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}
</style>
